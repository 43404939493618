import { withRouter } from "react-router-dom";
import CustomComponent from "../../components/CustomComponent";
import { Forbidden } from "../_error_pages";
import { getSession } from "../../helpers/helpers";

const REDIRECTIONS = [
    { permissions: ['DRT_AIDE_CNIEL'], path: '/aide/cniel', classes: ['CNI'] },
    { permissions: ['DRT_AIDE_LABORATOIRE'], path: '/aide/laboratoire', classes: ['CNI', 'LAB']},
    { permissions: ['DRT_AIDE_LAITERIE'], path: '/aide/laiterie', classes: ['CNI', 'LAB', 'LAI', 'OIP', 'ORG']},
    { permissions: ['DRT_AIDE_PRODUCTEUR'], path: '/aide/producteur', classes: ['CNI', 'LAB', 'LAI', 'PRO'] },
    { permissions: ['DRT_AIDE_OPNC'], path: '/aide/opnc', classes: ['CNI', 'LAB', 'OPN'] },
]

class AccueilAide extends CustomComponent {
    componentDidMount() {
        const session = getSession();
        for (const redirection of REDIRECTIONS) {
            if (redirection.classes.includes(session?.cla_ent)) return this.props.history.push(redirection.path)
        }
    }

    render () {
        return <Forbidden />
    }
}

export default withRouter(AccueilAide);