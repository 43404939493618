import { useMemo } from "react"
import { dateFormat } from "../helpers/helpers"

export interface AuditFieldsProps {
    audit: {
        per_creation?: any
        per_modif?: any
        date_creation?: string
        date_modif?: string
        date_suppr?: string
    }
}

export const AuditFields = ({audit} : AuditFieldsProps) => {
    const {
        per_creation,
        per_modif,
        date_creation,
        date_modif,
    } = audit;

    const nom_creation = useMemo(() => {
        if (!per_creation) return `l'import annuaire Infolabo`;
        if (per_creation?.date_suppr) return "Utilisateur supprimé";

        return `${per_creation.nom} ${per_creation.prenom}`;
    }, [per_creation]);

    const nom_modif = useMemo(() => {
        if (!per_modif) return `l'import annuaire Infolabo`;
        if (per_modif?.date_suppr) return "Utilisateur supprimé";

        return `${per_modif.nom} ${per_modif.prenom}`;
    }, [per_modif]);

    return (date_creation || date_modif) ? (
        <div className="container mt-auto">
            <div className="audit-fields">
                <i className="icon-info"></i>
                <ul>
                    {date_creation && (
                        <li data-testid="created_by">Créé le {dateFormat(date_creation, "DD/MM/YYYY")} par {nom_creation}</li>
                    )}
                    {date_modif && (
                        <li data-testid="updated_by">Dernière modification le {dateFormat(date_modif, 'DD/MM/YYYY')} par {nom_modif}</li>
                    )}
                </ul>
            </div>
        </div>
    ) : (<></>)
}