import { NavLink } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import { getSession } from "../../helpers/helpers";

const LINKS = [
    { name: 'CNIEL', slug: 'cniel', permissions: ['DRT_AIDE_CNIEL'], classes: ['CNI'] },
    { name: 'Laboratoire', slug: 'laboratoire', permissions: ['DRT_AIDE_LABORATOIRE'], classes: ['CNI', 'LAB'] },
    { name: 'Laiterie', slug: 'laiterie', permissions: ['DRT_AIDE_LAITERIE'], classes: ['CNI', 'LAB', 'LAI', 'OIP', 'ORG'] },
    { name: 'Producteur', slug: 'producteur', permissions: ['DRT_AIDE_PRODUCTEUR'], classes: ['CNI', 'LAB', 'LAI', 'PRO'] },
    { name: 'OPNC', slug: 'opnc', permissions: ['DRT_AIDE_OPNC'], classes: ['CNI', 'LAB', 'OPN'] },
]

type Props = {
    onHome?: boolean
    homeTab?: string
};

export const AideListHeader = ({ onHome, homeTab }: Props) => {
    const session = getSession();
    return (<>
        {!onHome && <BreadCrumb crumbs={[{ name: 'Aide' }]} />}
        <h1 className="main-title">Aide</h1>
        <ul className="nav nav-tabs m-t-10 m-t-md-0 m-r-md-15">
            {LINKS.map(tab =>
                !tab.classes || (tab.classes && tab.classes.includes(session?.cla_ent)) ?
                    <li key={tab.slug} className="nav-item">
                        <NavLink className="nav-link" to={!!onHome && homeTab?.toLocaleLowerCase() === tab.slug ? '' : `/aide/${tab.slug}`}>
                            {tab.name}
                        </NavLink>
                    </li>
                    :
                    <></>
            )}
        </ul>
    </>
    )
}